import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import axios from 'axios'
import UserImg from '../assets/user.svg'
import EmailImg from '../assets/email.svg'
import device from '../utils/mq'
import SEO from '../components/Seo'
// import Partners from '../components/sections/Partners'

const webhookURL = `https://hooks.zapier.com/hooks/catch/3484805/b5y8jcu/`
const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
`

const SignUpWrapper = styled.div`
  max-width: 1285px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0px 1.0875rem;

  @media screen and (min-width: 740px) {
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 80px;
  flex-direction: column;
  gap: 50px;

  @media ${device.tablet} {
    margin-top: 50px;
    flex-direction: row;
    margin-bottom: 200px;
    gap: 50px;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f8fc;
  flex: 1;
  padding: 25px 30px 20px 20px;
  border-radius: 4px;

  @media ${device.tablet} {
    flex: 3;
  }
`

const PartnersContainer = styled.div`
  flex: 1;

  @media ${device.tablet} {
    flex: 4;
  }
`

const PartnersTitle = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 38px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  padding-top: 20px;

  @media ${device.tablet} {
  }
`

const PartnersItems = styled.div`
  max-width: 930px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 30px;
`

const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
  }
`

const FormTitle = styled.h1`
  color: #000;
  font-size: 24px;
  line-height: 38px;
  font-weight: 900;
  margin: 0;

  @media ${device.tablet} {
  }
`

const FormInfo = styled.p`
  color: #000;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 5px;

  @media ${device.tablet} {
  }
`

const FormButtonContainer = styled.div`
  margin: 22px 0 10px;
`

const FormButton = styled.button`
  color: #fff;
  text-align: center;
  background-color: #5a55ab;
  padding: 10px 30px;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 22px;
  border: 2px solid #5a55ab;
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  max-width: 193px;
  // box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

  @media ${device.tablet} {
  }

  &:hover {
    color: #5a55ab;
    background-color: #fff;
    border-color: #fff;
  }
`
const FormConditions = styled.p`
  color: #000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;

  @media ${device.tablet} {
  }
`

const StyledInput = styled.input`
  background: #ffffff;
  border-radius: 4px;
  height: 3rem;
  padding: 1rem;
  border: 1px solid #d9e1ee;
  margin-top: 22px;
  color: #8494b2;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box;
  &:focus {
    display: inline-block;
    color: #000;
  }
  &::placeholder {
    color: #8494b2;
    line-height: 22px;
    font-size: 14px;
  }
`

const PartnerItem = styled.div`
  display: block;
  max-width: 120px;
  margin: 10px;
  justify-content: space-evenly;
  align-items: center;
  @media ${device.tablet} {
    margin: 15px 25px;
  }
`

const PartnerImg = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  div {
    max-height: 100%;
    max-width: 70%;
    @media ${device.tablet} {
      max-width: 90%;
    }
  }
`

const InlineError = styled.p`
  font-size: 12px;
  margin: 4px 0 0 0;
  padding: 0;
  background: none;
  font-weight: normal;
  color: #fb5854;
`

const tags = {
  hd: 'Homepage',
  csce: 'UC: Continuous Education',
  csob: 'UC: Onboarding',
  csit: 'UC: Internal Training',
  cscp: 'UC: Career Paths',
  csc: 'UC: Certifications',
  cs: 'Innential Content Search',
  cl: 'Innential Content Library',
}

const SignUp = ({ data: { allContentfulSectionPartners } }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const partners =
    allContentfulSectionPartners.nodes[0].data.length <
    allContentfulSectionPartners.nodes[1].data.length
      ? allContentfulSectionPartners.nodes[0].data
      : allContentfulSectionPartners.nodes[1].data

  const [emailError, setEmailError] = useState('')
  const [nameError, setNameError] = useState('')

  const safeWindow = typeof window === 'undefined' ? {} : window

  const ref = new URLSearchParams(safeWindow?.location?.search).get('ref')

  const onSubmit = async (e) => {
    let error = false

    if (name === '') {
      e.preventDefault()
      setNameError('Please enter your name')
      error = true
    }

    if (email === '') {
      e.preventDefault()
      setEmailError('Please enter your work email')
      error = true
    } else if (!emailRegex.test(email)) {
      e.preventDefault()
      setEmailError('Please enter a valid email')
      error = true
    }

    if (error) {
      return false
    }

    try {
      await axios.post(
        webhookURL,
        JSON.stringify({
          email,
          name,
          time: new Date(),
          tag: tags[ref],
        })
      )
    } catch (err) {
      console.error(err)
    }
    return true
  }

  return (
    <>
      <SEO
        title="Innential - Sign Up"
        description="Ready to learn more?"
        meta={[{ name: 'robots', content: 'noindex' }]} // PREVENT INDEXING THE PAGE
      />
      <Wrapper>
        <SignUpWrapper>
          <Container>
            <FormContainer>
              <div>
                <FormTitle>Ready to learn more?</FormTitle>
                <FormInfo>
                  Fill out the form and we'll be in touch ASAP.
                </FormInfo>
              </div>
              <FormInputContainer>
                <StyledInput
                  type="text"
                  placeholder="Your name"
                  img={UserImg}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onFocus={() => setNameError('')}
                />
                {nameError && <InlineError>{nameError}</InlineError>}
                <StyledInput
                  type="email"
                  placeholder="Your work email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  img={EmailImg}
                  onFocus={() => setEmailError('')}
                />
                {emailError && <InlineError>{emailError}</InlineError>}
              </FormInputContainer>
              <FormButtonContainer>
                <Link
                  to={`/appointment?email=${email}&name=${name}`}
                  onClick={onSubmit}
                >
                  <FormButton>Request more info</FormButton>
                </Link>
              </FormButtonContainer>
              <FormConditions>
                By submitting this form, you agree to our{' '}
                <Link to="/data-privacy">Privacy Policy </Link> and Terms &
                Conditions.
              </FormConditions>
            </FormContainer>
            {/* <Partners
          headline="Great companies use Innential to develop their employees into superstars."
          partnersData={allContentfulPartners.nodes}
        /> */}
            <PartnersContainer>
              <PartnersTitle>
                Great companies use Innential to develop their employees into
                superstars.
              </PartnersTitle>
              <PartnersItems>
                {partners.map((item) => (
                  <PartnerItem key={item?.details?.name}>
                    <PartnerImg>
                      <img
                        src={item?.details?.image?.file?.url}
                        alt={item?.details?.name}
                      />
                    </PartnerImg>
                  </PartnerItem>
                ))}
              </PartnersItems>
            </PartnersContainer>
          </Container>
        </SignUpWrapper>
      </Wrapper>
    </>
  )
}

export default SignUp

export const query = graphql`
  query {
    allContentfulSectionPartners {
      nodes {
        data {
          id
          details {
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
